p,
ul,
ol,
dl,
a {
  font-family: 'Lora', serif;
  font-size: 18px;
  line-height: 26px;

  @media screen and (max-width: $size-640) {
    font-size: 14px;
    line-height: 22px;
  }
}

a {
  color: $color-blue;
  text-decoration: none;

  &:hover {
    color: lighten($color-blue, 10%);
  }

  &:visited {
    color: darken($color-blue, 10%);

    &:hover {
      color: $color-blue;
    }
  }
}

h2 {
  margin-top: 0;
  margin-bottom: 36px;
  font-family: 'Sacramento', cursive;
  font-size: 42px;
  text-align: center;
  font-weight: 400;

  @media screen and (max-width: $size-640) {
    font-size: 32px;
    margin-bottom: 22px;
  }

  @media screen and (max-width: $size-420) {
    margin-top: 20px;
    margin-bottom: 24px;
  }
}

.headline-break {
  display: none;

  @media screen and (max-width: $size-470) {
    display: inherit;
  }
}

dt {
  font-weight: bold;
}

dd {
  margin: 14px 0 0;

  @media screen and (max-width: $size-640) {
    margin-top: 10px;
  }

  & + dt {
    margin-top: 36px;

    @media screen and (max-width: $size-640) {
      margin-top: 28px;
    }
  }
}
